import React from 'react'
import background from "./Background.png"
import Footer from './Footer'
import "./home.css";

export default function FAQ() {
    const faq = [
        ['Is there a menu for your meal prep services?', 'Yes there is a menu, the menu is specifically for you! We go over your likes, dislikes, dietary needs, allergies,etc., and with that information I create a menu! Once you decide what you want, I get to cooking!'],
        ['Are your snacks gluten free?', 'Yes! All of my snacks and OverKnight Oats are gluten free!'],
        ['Do I need to be on a diet to use your meal prep services?', 'Absolutely not! I will prep for clients that simply do not have time to cook dinner, or if you just do not feel like cooking for yourself!'],
        ['Are the snacks made to order?', 'Yes! Every snack and OverKnight bag is made to order! Usually within 24 hours!'],
        ['Do you deliver the meal prep, or do I need to pick up?', 'Yes, I deliver for FREE within a 30 minute drive (which is I believe a 10 mile radius). Pick up is an option if you would like to pick up as well'],
        ];
    return (
        <div class="frontpage" style={{
            backgroundImage: 'url('+background+')',
            }}>
    <div className="faq">
        <div className="middle">
            <div className="bottom">
                <h2>Frequently Asked Questions</h2>
                <div>
                {
                    faq.map((textArr, index) => {
                        const question = textArr[0];
                        const answers = textArr.slice(1);
                        return <div className="faqna" key={index}>
                                <h3>
                                    <svg class="faqimg" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                    {question}
                                </h3>
                                <ul className="max-w-md text-gray-500 list-disc">
                                    {
                                        answers.map(answer => {return <li className="ml-10">{answer}</li>;})
                                    }
                                </ul>
                            </div>
                            ;
                        })
                } 
                </div>
            </div>
        </div>
        </div>
    <Footer />
    </div>
    );
}