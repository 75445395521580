import Navbar from "./Navbar"
import Home from "./pages/Home"
import About from "./pages/About"
import FAQ from "./pages/FAQ"
import Refund from "./pages/Refund"
import { Route, Routes } from "react-router-dom"

function App() {
  return <>
    <Navbar />
    <div className="container">
      <Routes>
        <Route path="/" element= {<Home />} />
        <Route path="/About" element= {<About />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/Refund" element={<Refund />} />
      </Routes>
    </div>
  </>
}

export default App;
