import "./home.css"
import fb from "./fb.png"
import insta from "./insta.png"
import logomono from "./logomono.png"
import { Link } from "react-router-dom";

export default function Footer() {
    return  <div>
<section class="foot">
<section class="eclectic">
    <img src={logomono} width="200" height="160" />
<section class="eclecticinfo">
    <h2>Pack'D Kitchen</h2>
    <h2>443-244-2095</h2>
    <h2>packdkitchenllc@gmail.com</h2>
</section>
</section>
<section class="footdesc">
<section class="center">
    <h1><b>Contact</b></h1>
    <h2><a href="mailto: johnlindemon@hbindustry.co?">Problems with Site</a></h2>
    <h2><a href="mailto: packdkitchenllc@gmail.com?">Email David</a></h2>
</section>
<section class="center">
    <h1><b>Associates Links</b></h1>
    <h2><a href="danikgymandwellness.com">Dani K Gym</a></h2>
    <h2><a href="hbindustry.co">HBIndustry</a></h2>
</section>
<section class="center">
    <h1><b>Resources</b></h1>
    <h2>Graphics by <a href="hbindustry.co">HBIndustry</a></h2>
    <h2><Link to="/FAQ">
            Frequently Asked Questions
        </Link></h2>
    <h2><Link to="/Refund">
            Refund Policy
        </Link></h2>   
</section>
<section class="center">
    <h1><b>Social</b></h1>
    <h2><a href="https://www.facebook.com/people/Packd-Kitchen-LLC/61551925152015/"><img src={fb} width="40" height="40" /></a></h2>
    <h2><a href="https://www.instagram.com/packdkitchenllc/"><img src={insta} width="40" height="40" /></a></h2>
</section>
</section>
</section>
</div>
}