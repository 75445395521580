import "./home.css"
import { Link } from "react-router-dom"
import background from "./Background.png"
import head from "./head.png"
import logomono from "./logomono.png"
import event1 from "./event1.jpg"
import event2 from "./event2.jpg"
import event3 from "./event3.jpg"
import fb from "./fb.png"
import insta from "./insta.png"
import axios from "axios"
import { React, useEffect, useState } from "react"
import { Box } from "@mui/material"
import { Rating } from "@mui/material"

export default function About() {
    const [title, setTitle] = useState('');
    const [rating, setRating] = useState(0);
    const [message, setMessage] = useState('');

    const [reviews, setReviews] = useState([]);

    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [tooLongMessage, setTooLongMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newReview = { title, rating, message };
        if (rating > 0 && title !== '') {
            if (message.length > 600) {
                setTooLongMessage('Message too long. Maximum 600 characters allowed.');
                setTimeout(() => {
                    setTooLongMessage('');
                }, 5000);
            } else {
                try {
                    await axios.get(`/api/reviews/add?title=${title}&rating=${rating}&message=${message}`)

                    setReviews([...reviews, newReview]);

                    setTitle('');
                    setRating(0);
                    setMessage('');

                    setSuccessMessage("Success! Thank you for submitting!");
                    setTimeout(() => {
                        setSuccessMessage('');
                    }, 5000);

                    await getReviews();

                } catch (error) {
                    console.error("There was an error submitting the review: ", error);
                }
            }
        } else {
            setErrorMessage("Please enter a rating and title before submitting.");

            setTimeout(() => {
                setErrorMessage('');
            }, 5000);
        }

    };

    useEffect(() => {
        getReviews();
    }, []);

    const getReviews = async () => {
        try {
            const response = await axios.get('/api/reviews/getReviews')
            setReviews(response.data);
        } catch (error) {
            console.error("There was an error retrieving the reviews", error);
        }
    }

    return <div>
        <section class="frontpage" style={{
            backgroundImage: 'url(' + background + ')',
        }}>
            <section class="outside">
            </section>
            <section class="outside">
                <section class="box">
                    <section class="fill">
                        <h1>David Pack; Owner and CEO</h1>
                        <img src={head} width="300" height="300" />
                        <section class="content">
                            <h2><b>Accolades</b></h2>
                            <p>Hello and welcome to my Pack'd Kitchen! I have been a chef for 7 years now, with a mix of professional and personal experiences. My goal is to bring the restaurant experience to your home, your meal prep, or at your next event! I have helped numerous clients since the opening of Pack'd Kitchen and I hope I can help you too!</p>
                        </section>
                    </section>
                </section>
            </section>
            <section class="outside">
                <section class="middle">
                    <section class="leftbox">
                        <h1>We Cater</h1>
                        <section class="eventrow">
                            <section class="mpic">
                                <img src={event1} width="250" height="300" />
                            </section>
                            <section class="mpic">
                                <img src={event3} width="250" height="300" />
                            </section>
                        </section>
                        <section class="eventrow">
                            <section class="mpic">
                                <img src={event2} width="600" height="400" />
                            </section>
                        </section>
                        <p>Pack'd Kitchen LLC also offers catering! Partnered with County Line in Hampstead, we can cater to any event that you have scheduled! Call or email today to book!</p>
                    </section>
                    <section class="rightbox">
                        <h1 className="text2xl font-bold mb-8 text-center">Leave a Review</h1>

                        <div className="flex flex-col gap-4 p-4">
                            <label className="block text-sm text-left font-semibold text-gray-900">Title:</label>
                            <input
                                type="text"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                className="w-full p-2 border border-gray-300 rounded mt-1" />
                            <label className="block text-sm text-left font-semibold text-gray-900">
                                Rating:
                            </label>
                            <Box
                                className="flex justify-center"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Rating
                                    name="hover-feedback"
                                    value={rating}
                                    precision={1.0}
                                    onChange={(event, newValue) => {
                                        setRating(newValue);
                                    }}
                                    onChangeActive={(event, newHover) => {
                                        // handle hover state changes if necessary
                                    }}
                                />
                            </Box>
                            <label className="block text-sm text-left font-semibold text-gray-900">
                                (Optional) Message:
                            </label>
                            <textarea
                                className="mt-1 block p-2 w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 h-24" // Increased height for a larger text box
                                type="text"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                placeholder="Type your message here..." />
                            <button type="submit" onClick={handleSubmit}>Submit</button>
                            <div className="text-sm">
                                {tooLongMessage && <p>{tooLongMessage}</p>}
                            </div>
                            <div className="text-sm">
                                {successMessage && <p>{successMessage}</p>}
                            </div>
                            <div className="text-sm">
                                {errorMessage && <p>{errorMessage}</p>}
                            </div>
                        </div>
                        <div>
                            <p>Reviews</p>
                            {reviews && reviews.map((review, index) => (
                                <div key={index} className="bg-gray-400 border-gray-300 text-gray-900 p-4 rounded-lg shadow-md mb-4">
                                    <h3 className="text-lg font-semibold">{review.title}</h3>
                                    <Rating name="read-only" value={review.rating} readOnly />
                                    <p className="text-md">{review.message}</p>
                                </div>
                            ))}
                        </div>

                    </section>

                </section>
            </section>
            <section class="foot">
                <section class="eclectic">
                    <img src={logomono} width="200" height="160" />
                    <section class="eclecticinfo">
                        <h2>Pack'D Kitchen</h2>
                        <h2>443-244-2095</h2>
                        <h2>packdkitchenllc@gmail.com</h2>
                    </section>
                </section>
                <section class="footdesc">
                    <section class="center">
                        <h1><b>Contact</b></h1>
                        <h2><a href="mailto: johnlindemon@hbindustry.co?">Problems with Site</a></h2>
                        <h2><a href="mailto: packdkitchenllc@gmail.com?">Email David</a></h2>
                    </section>
                    <section class="center">
                        <h1><b>Associates Links</b></h1>
                        <h2><a href="danikgymandwellness.com">Dani K Gym</a></h2>
                        <h2><a href="hbindustry.co">HBIndustry</a></h2>
                    </section>
                    <section class="center">
                        <h1><b>Resources</b></h1>
                        <h2>Graphics by <a href="hbindustry.co">HBIndustry</a></h2>
                        <h2><Link to="/FAQ">
                            Frequently Asked Questions
                        </Link></h2>
                        <h2><Link to="/Refund">
                            Refund Policy
                        </Link></h2>
                    </section>
                    <section class="center">
                        <h1><b>Social</b></h1>
                        <h2><a href="https://www.facebook.com/people/Packd-Kitchen-LLC/61551925152015/"><img src={fb} width="40" height="40" /></a></h2>
                        <h2><a href="https://www.instagram.com/packdkitchenllc/"><img src={insta} width="40" height="40" /></a></h2>
                    </section>
                </section>
            </section>
        </section>
    </div >
}
